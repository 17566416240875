import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProfilePic from "../components/profilePic";

import "./global.scss";

const IndexPage = data => {
  return (
    <Layout>
      <SEO title="About" />
      <section id="about">
        <div className="about_desc">
          <p>Hi, I'm Vanessa. </p>
          <p>
            Building websites and web applications, currently based in Jakarta,
            Indonesia. Previously working in Singapore.
          </p>

          <p>
            Interested in technology, education, armchair philosophy, and
            Japanese culture.
          </p>

          <p>A curious person. A believer in lifelong learning.</p>

          <p>
            Say hi on Twitter{" "}
            <a
              href="https://twitter.com/vancassa"
              target="_blank"
              rel="noopener noreferrer"
            >
              @vancassa
            </a>{" "}
          </p>
        </div>
        <div className="about_pic">
          <ProfilePic />
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;
